import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import RatingBox from "../components/RatingBox";
import ImageSlider from "../components/ImageSlider";
import { SliderImage } from "../../types";

import data from "../resources/index-page.json";

import "./IndexPage.scss";

const brideImages = data.brideImages as unknown as SliderImage[];
const groomImages = data.groomImages as unknown as SliderImage[];

export default function IndexPage(): JSX.Element {
  return (
    <main className="index-page">
      <Helmet>
        <title>
          Brautkleider, Anzüge, Accessoires in Taucha und Leipzig -
          Hochzeitsausstatter Bachmann
        </title>
        <meta
          name="description"
          content="Braut und Bräutigammode, individuell zusammengestellt. Alles für Ihre Hochzeit mit individueller Beratung"
        />
      </Helmet>
      <section className="index-page-content-box">
        <div className="index-page-content">
          <h1>Outlet-Brautkleider von 150,- bis 899,- €</h1>
          <h2>Bitte nur mit Termin</h2>
          <p>Ratenzahlung bis zur Abholung möglich.</p>
        </div>
      </section>
      <section className="index-page-content-box">
        <div className="index-page-content">
          <h1>Hallo liebe Braut,</h1>
          <p>
            wir freuen uns, dass dich deine Suche nach deinem perfekten
            Brautkleid auf unsere Seite geführt hat. Wir sind ein Familien
            geführtes Unternehmen, welches sich seit über 30 Jahren, um die
            Belange zum Thema Hochzeit kümmert. Aktuell wird das Unternehmen in
            zweiter Hand geführt.
          </p>
          <p>
            Mit viel Liebe zum Detail suchen wir dein perfektes Brautkleid für
            deinen schönsten Tag im Leben. Bei uns findest du Brautmode und
            Accessoires in unterschiedlichen Stilrichtungen von{" "}
            <strong>Vintage</strong> bis <strong>Prinzessin</strong> über sexy
            bis elegant.
          </p>
          <p>
            Du hast bei uns die Möglichkeit verschieden Stilrichtungen
            auszuprobieren um schlussendlich dein Brautkleid zu finden, dass
            dich und vor allem dein Herz strahlen lässt.
          </p>
          <p>Komm vorbei und probiere dich durch.</p>
        </div>
      </section>

      <section className="index-page-content-box">
        <div className="index-page-content index-page-content-bride">
          <ImageSlider images={brideImages} autoscroll />
          <div className="index-page-content-bride-text">
            <h2>Unsere Brautkleid Kollektionen</h2>
            <p>
              Damit du bereits vor deinem Besuch bei uns einen Eindruck
              bekommst, findest du bei uns eine große Auswahl an Brautkleidern,
              der Marken <strong>Ladybird</strong>, <strong>MS-Moda</strong>,{" "}
              <strong>Kaledonia</strong>, <strong>EuroMode</strong>,{" "}
              <strong>Miss Kelly</strong>, <strong>Tres Chic</strong>,{" "}
              <strong>Amelie</strong>, <strong>Eglantine</strong> und{" "}
              <strong>Fuchs</strong>.
            </p>
            <Link to="/fuer-die-braut" className="index-page-content-link">
              Alle Kollektionen anzeigen
            </Link>
          </div>
        </div>
      </section>
      <section className="index-page-content-box">
        <div className="index-page-content index-page-content-groom">
          <ImageSlider images={groomImages} autoscroll />
          <div className="index-page-content-bride-text">
            <h2>Unsere Anzug Kollektionen</h2>
            <p>
              Da bei jeder Hochzeit das Brautkleid im Mittelpunkt steht sollte
              der Anzug in Farbe und Stil abgestimmt sein. Du findest bei uns
              eine große Auswahl an Anzügen, der Marken{" "}
              <strong>Cool Classics</strong>, <strong>Aftersix</strong>,{" "}
              <strong>Wilvorst</strong>.
            </p>
            <Link to="/fuer-den-braeutigam" className="index-page-content-link">
              Alle Kollektionen anzeigen
            </Link>
          </div>
        </div>
      </section>
      <section className="index-page-content-box">
        <div className="index-page-content">
          <RatingBox />
        </div>
      </section>
      <section className="index-page-content-box">
        <div className="index-page-content">
          <h3>Tipp 10 bis 8 Monate vor der Hochzeit</h3>
          <p>
            Bestimmen Sie den Termin. Reden Sie dazu mit Freunden und Verwandten
            - aber Vorsicht! Allen kann man es fast nie recht machen. Treffen
            Sie eine Entscheidung (allgemeine Feiertage und Urlaubszeit
            beachten). Meistens passt der Termin dann doch. Legen Sie den Rahmen
            der Hochzeitsfeier fest - klein oder ganz groß, "nur" standesamtlich
            oder auch kirchlich.
          </p>

          <p>
            Schauen und hören Sie sich nach einem geeigneten Standesamt um.
            Führen Sie Gespräche - u.U. auch mit Ihrer Kirchengemeinde. Welche
            Formalitäten müssen erledigt werden, welche Dokumente werden
            benötigt?
          </p>

          <p>
            Über die Trauzeugen sollten Sie sich frühzeitig Gedanken machen! Am
            Besten noch, bevor Sie Ihre Pläne bekannt geben. Nichts ist
            schlimmer als Hoffnungen, die nicht erfüllt werden. Denken Sie auch
            an eventuelle Brautjungfern und Blumenkinder. Stellen Sie einen
            allgemeinen Kostenplan auf, so ersparen Sie sich Überraschungen!
          </p>

          <p>
            Führen Sie ein Gespräch mit Ihren Eltern und Schwiegereltern. Denn
            auch diese haben - auch aus traditionellen Gründen - ihre Gedanken
            zum Ablauf und zur Finanzierung einzelner Teile der Hochzeit. Mit
            Fingerspitzengefühl kann man Enttäuschungen vermeiden.
          </p>
        </div>
      </section>
    </main>
  );
}
